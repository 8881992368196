import { INavData } from '@coreui/angular-pro';

export const navItems: INavData[] = [
  {
    name: 'Transactions',
    url: '/transactions',
    iconComponent: { name: 'cil-send' }
  },
  {
    name: 'Archives',
    url: '/archives',
    iconComponent: { name: 'cil-archive' },
  },
  {
    name: 'Trash',
    url: '/trash',
    iconComponent: { name: 'cil-trash' },
  },
  {
    name: 'Documents',
    url: '/documents',
    iconComponent: { name: 'cil-document' },
  },
  {
    name: 'Contacts',
    url: '/contacts',
    iconComponent: { name: 'cil-contact' },
  },
];
