import { Component, OnInit } from '@angular/core';
import { MsalService} from '@azure/msal-angular';

@Component({
  templateUrl: 'logout.component.html',
  styleUrls: ['logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor( private authService: MsalService) { }
  
  ngOnInit(): void {
    this.authService.logoutRedirect({
     
    });
  }
}
